import React from "react"
import styled from "@emotion/styled"
import articles from "../articles-db"
import blogImg from "../images/blogImg.jpg"
import { Link } from "gatsby"
import hklogo from "../images/hk-logo.png"

const Blog = () => {
  return (
    <BlogSection>
      <BlogMainImage>
        <StyledMainImage src={hklogo}></StyledMainImage>
      </BlogMainImage>
      <BlogContainer>
        {articles.map(({ id, link, title, image, date, intro }) => (
          <Link
            to={`/${link}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            <ArticleContainer>
              <ArticleImageContainer>
                <img src={image} alt={title} className="articleImage"></img>
              </ArticleImageContainer>
              <ArticleTextContainer>
                <ArticleTitle>{title}</ArticleTitle>
                <ArticleDate>{date}</ArticleDate>
                <ArticleText>{intro}</ArticleText>
                <Link
                  to={`/${link}`}
                  style={{
                    outline: "none",
                    color: "red",
                    textDecoration: "none",
                  }}
                >
                  Lire la suite
                </Link>
              </ArticleTextContainer>
            </ArticleContainer>
          </Link>
        ))}
      </BlogContainer>
    </BlogSection>
  )
}

const BlogSection = styled.section``

const BlogContainer = styled.div`
  /* margin-top: 400px; */
  padding: 0 10%;
`

const BlogMainImage = styled.div`
  width: 100%;
  height: 50vh;
  background-image: url(${blogImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 100px;
  position: sticky;
  top: -38%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
`
const StyledMainImage = styled.img`
  z-index: 100;
  width: 400px;

  @media screen and (max-width: 640px) {
    width: 70%;
  }
`

const ArticleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`

const ArticleTextContainer = styled.div`
  width: 80%;
  padding: 20px 60px;

  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 20px;
  }
`

const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
`
const ArticleDate = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
`
const ArticleText = styled.p`
  font-size: 15px;
  max-width: 600px;
  margin-bottom: 10px;
`

const ArticleImageContainer = styled.div`
  width: 16vw;
  height: 16vw;
  background-color: #fff;

  .articleImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 640px) {
    width: 80vw;
    height: 80vw;
  }
`

export default Blog
