import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Blog from "../components/Blog"

const IndexPage = () => (
  <Layout headerColor={"red"}>
    <SEO
      title="Le blog culinaire d'hara kiri ramen"
      description="A travers ce blog, vous trouverez toutes les informations qui vont vous permettre de connaitre, faire, découvrir les ramens d'hara kiri"
    />
    <Blog />
  </Layout>
)

export default IndexPage
